<template>
  <div class="c-merge-client-dialog">
    <el-dialog
      v-el-drag-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="visible"
      @dragDialog="handleDrag">
      <section class="c-merge-client-dialog_list">
        <p class="c-merge-client-dialog_title">待合并客户列表</p>
        <div>
          <grid
            ref="list"
            :columns="columns"
            :datas="itemSource"
            :selection="true"
            :suppressDragLeaveHidesColumns="true"
            :suppressPaginationPanel="false"
            domLayout="autoHeight"
            headerHeight="35"
            type="base"
            >
          </grid>
        </div>
      </section>
      <section class="c-merge-client-dialog_box">
        <p class="c-merge-client-dialog_title">合并后客户信息</p>
        <div class="c-merge-client-dialog_content">
          <znl-input
            v-model="editItem.CompanyName"
            border
            class="znl-input"
            placeholder=""
            title="客户名称: "
            title-width="70px"
            width="400px">
          </znl-input>
          <znl-input
            v-model="editItem.CompanyCode"
            border
            class="znl-input"
            placeholder=""
            title="客户编码: "
            title-width="70px"
            width="150px">
          </znl-input>
          <znl-input
            v-model="editItem.Phone"
            border
            class="znl-input"
            placeholder=""
            title="固定电话: "
            title-width="60px"
            width="200px">
          </znl-input>
          <znl-input
            v-model="editItem.Fax"
            border
            class="znl-input"
            placeholder=""
            title="传真: "
            title-width="40px"
            width="160px">
          </znl-input>
        </div>
        <div class="c-merge-client-dialog_content">
          <znl-input
            v-model='editItem.FullAddress'
            border
            class="znl-input"
            placeholder=""
            title="客户地址: "
            title-width="70px"
            width="400px">
          </znl-input>
          <znl-input
            v-model="editItem.ReceivingAddress"
            border
            class="znl-input"
            placeholder=""
            title="收货地址: "
            title-width="70px"
            width="400px">
          </znl-input>
        </div>
        <div class="c-merge-client-dialog_content">
          <znl-input
            v-model="editItem.Introduction"
            border
            class="znl-input"
            placeholder=""
            title="客户简历: "
            title-width="70px"
            type="textarea"
            width="400px">
          </znl-input>
          <znl-input
            v-model="editItem.Remark"
            border
            class="znl-input"
            placeholder=""
            title="客户备注: "
            title-width="70px"
            type="textarea"
            width="400px">
          </znl-input>
        </div>

        <div class="c-merge-client-dialog_content c-merge-client-dialog_attribute">
          <p v-for="(item, index) in attributeList" :key="index" style="display: inline-block">
            <znl-input
              v-model="item['LineValue']"
              :title="`${item['Name']}:` "
              border
              class="znl-input"
              placeholder=""
              title-width="70px"
              width="180px">
            </znl-input>
          </p>

        </div>
        <div class="c-merge-client-dialog_content">
          <znl-input
            v-model="editItem.RegisteredCapital"
            border
            class="znl-input"
            placeholder=""
            title="注册资金: "
            title-width="70px"
            width="180px">
          </znl-input>
          <znl-input
            v-model="editItem.BusinessLicense"
            border
            class="znl-input"
            placeholder=""
            title="营业执照号: "
            title-width="70px"
            width="200px">
          </znl-input>
        </div>

        <div class="c-merge-client-dialog_content">
          <znl-input
            v-model="editItem.CorporateName"
            border
            class="znl-input"
            placeholder=""
            title="对公名称: "
            title-width="70px"
            width="400px">
          </znl-input>
          <znl-input
            v-model="editItem.OpenAccountPhone"
            border
            class="znl-input"
            placeholder=""
            title="开户电话: "
            title-width="70px"
            width="150px">
          </znl-input>
          <znl-input
            v-model="editItem.BankAccount"
            border
            class="znl-input"
            placeholder=""
            title="对公帐号: "
            title-width="70px"
            width="200px">
          </znl-input>
          <znl-input
            v-model="editItem.BankAccount"
            border
            class="znl-input"
            placeholder=""
            title="开户银行: "
            title-width="70px"
            width="160px">
          </znl-input>
        </div>

        <div class="c-merge-client-dialog_content">
          <znl-input
            v-model="editItem.ClearingForm"
            border
            class="znl-input"
            placeholder=""
            title="结算方式: "
            title-width="70px"
            width="190px">
          </znl-input>
          <znl-input
            v-model="editItem.CurrencyCode"
            border
            class="znl-input"
            placeholder=""
            title="结算币别: "
            title-width="70px"
            width="200px">
          </znl-input>
          <znl-input
            v-model="editItem.TaxRate"
            border
            class="znl-input"
            placeholder=""
            title="税率方式: "
            title-width="70px"
            width="150px">
          </znl-input>
          <znl-input
            v-model="editItem.BillingRemark"
            border
            class="znl-input"
            placeholder=""
            title="开票备注: "
            title-width="70px"
            width="370px">
          </znl-input>
        </div>
      </section>
      <section class="m-margin--top-10 m-dialog-footer">
        <el-button type="primary" @click="onSave">保存</el-button>
        <el-button @click="visible = false">取消</el-button>
      </section>
    </el-dialog>
  </div>
</template>
<script>
import elDragDialog from "@/directive/el-drag-dialog"; // base on element-ui
import {mergeClientColumns} from "./config";
import {getMergeClient, editMergeClient} from "../../../../api/client";


export default {
  name: "MergeClient",
  directives: {
    elDragDialog,
  },
  data() {
    return {
      visible: false,
      title: "客户合并",
      columns: mergeClientColumns,
      isEdit: false,
      editItem: {
        Ids: [], //选择的全部客户ID
        ChoiceCompanyId: 0, //选择的哪个公司ID（选择的公司名）
        ChoiceAdressId: 0, //选择的送货地址ID（公司ID）
        ChoiceAttributeId: 0, //选择的客户属性ID（公司ID）
        CompanyCode: "", //客户编码
        CompanyName: "", //公司名称
        Phone: "", //电话
        Fax: "", //公司传真
        Province: "", //省份
        City: "", //城市
        Area: "", //区域
        Address: "", //公司地址
        Introduction: "", //简介
        Remark: "", //备注
        Mobile: "", //手机号
        RegisteredCapital: '', //注册资金
        BusinessScope: "", //经营范围
        BusinessLicense: "", //营业执照号
        VATNumber: "", //增值税号
        CorporateName: "", //对公名称
        OpenAccountPhone: "", //开户电话
        BankAccount: "",
        IsInvoice: true, //是否开票
        ClearingForm: "", //结算方式
        TaxAddress: "", //开票地址， 寄送信息
        CurrencyCode: "", //使用币种
        BillingRemark: "",   //开单备注
        TaxRate: '',           // 税率
        FullAddress: '',      // 客户地址
        ReceivingAddress: '', // 收货地址
      },
      attributeList: [],
      itemSource: [
        {
          rowIndex: 1,
          companyName: 'A',
          phone: '121234234234',
        },
        {
          rowIndex: 2,
          companyName: 'B',
          phone: '12123234',
        },
        {
          rowIndex: 3,
          companyName: 'C',
          phone: '456455',
        },
      ],
      selectCodes: []
    };
  },
  methods: {
    handleDrag() {
      // 拖拽事件
    },

    async onOpen(ids) {
      const data = await getMergeClient(ids)
      this.editItem.Ids = ids
      data.forEach((item, index) => {
        item.rowIndex = index + 1
        item.FullAddressInfo = {
          Province: item.Province,
          City: item.City,
          Area: item.Area
        }
        item.LicenseMusterInfo = {
          RegisteredCapital: item.RegisteredCapital,
          BusinessLicense: item.BusinessLicense,
          BusinessScope: item.BusinessScope
        }
        item.InvoicingMusterInfo = {
          VATNumber: item.VATNumber,
          CorporateName: item.CorporateName,
          OpenAccountPhone: item.OpenAccountPhone,
          BankAccount: item.BankAccount
        }
        item.SettlementMusterInfo = {
          ClearingForm: item.ClearingForm,
          CurrencyCode: item.CurrencyCode,
          TaxRate: item.TaxRate,
          BillingRemark: item.BillingRemark
        }
        Object.keys(item).forEach(key => {
          const code = `isSelect${key}`
          item[code] = false
        })
      })

      this.itemSource = data
      this.visible = true
      this.$nextTick(async () => {
        await this.initColumns()
      })


// 选择的字段集合
      const selectCodes = this.columns.filter(item => (item.field !== 'rowIndex' && item.field !== 'isAllCheck'))
      this.selectCodes = selectCodes.map(item => `isSelect${item.field}`)


    },

setEditAloneItem(item, field, isCheck) {

      console.log(item, field, isCheck)

      const waitOrder = ['FullAddress', 'LicenseMuster', 'InvoicingMuster', 'SettlementMuster']


      if (waitOrder.includes(field)) {
        const key = `${field}Info`
        const selectInfo = item[key]
        Object.keys(selectInfo).forEach(key => {
          this.editItem[key] = isCheck ? selectInfo[key] : null
        })

      }

      if (field === 'AttributeMuster') {
        this.setData(item['CustomerDictItemMuster'])
        this.editItem.ChoiceAttributeId = isCheck ? item['CompanyID'] : 0
      }

      if (field === 'CompanyName') {
        this.editItem.ChoiceCompanyId = isCheck ? item['CompanyID'] : 0
      }

      if (field === 'ReceivingAddress') {
        this.editItem.ChoiceAdressId = isCheck ? item['ReceivingAddressId'] : 0
      }


      this.editItem[field] = isCheck ? item[field] : null

    },

  //  setEditItem (item, field, code) {
  //     const waitOrder = ['FullAddress', 'LicenseMuster', 'InvoicingMuster', 'SettlementMuster']

  //     if (waitOrder.includes(field)) {
  //       const key = `${field}Info`
  //       const selectInfo = item[key]
  //       Object.keys(selectInfo).forEach(key => {
  //         this.editItem[key] = selectInfo[key]
  //       })
  //     } else if (field === 'AttributeMuster') {
  //       this.attributeList = item['CustomerDictItemMuster']
  //       this.editItem.ChoiceAttributeId = item['CompanyID']
  //     } else if (field === 'CompanyName') {
  //       this.editItem.ChoiceCompanyId = item['CompanyID']
  //     } else if (field === 'ReceivingAddress') {
  //       this.editItem.ChoiceAdressId = item['ReceivingAddressId']
  //     }
  //     this.editItem[field] = item[field]

  //     this.itemSource.forEach(line => {
  //       if (line.rowIndex !== item.rowIndex) {
  //         line[code] = false
  //       } else {
  //         line[code] = true
  //       }
  //     })

  //     this.$refs.list.setRowData(this.itemSource)
  //   },

    async onSave() {
      const {editItem} = this
      await editMergeClient(editItem)
      this.$message.success('客户合并成功')
      this.visible = false
    },

 onSetSelect(data) {
      
      /*
        code === isAllCheck 检查全选   code === isAllCheck 检查单选
        1. 全选
           检查 操作行 isCheck 状态
           isCheck === true   选中当前行的数据 清除其他的行数
           isCheck === false  清除当前行数据 获取其他数据

        2. 单选
           2.1 检查 有没有 全选
               全选状态   1. 操作单元 属于     全选行  改变该操作单元数据
                         2. 操作耽于 不属于    全选行  取消全选 改变该操作单元数据
               无全选状态 检查 该操作单元 isCheck
                         1. isCheck == true  加载当前行数据  取消其他操作单元的数据
                         2. isCheck == false 取消操作单元的数据

      */

      const {selectCodes} = this

      const {code, index, isCheck} = data

      const isAllCheck = code === 'isAllCheck'

      if (isAllCheck) {
        const selectItems = this.itemSource.find(item => item.rowIndex === index)
        console.log(selectItems)
        switch (isCheck) {
          case true:
            this.itemSource.forEach(line => {
              Object.keys(line).forEach(key => {
                line[`isSelect${key}`] = line.rowIndex === index
              })
              line.isAllCheck = line.rowIndex === index
            })

            // 处理数据
            break

          case false:
            Object.keys(selectItems).forEach(key => {
              selectItems[`isSelect${key}`] = false
            })

            // 处理数据
            break

          default:
            break
        }

        this.setAllSelect(selectItems, isCheck)
      }

      if (!isAllCheck) {
        const selectItem = this.itemSource.find(item => item.rowIndex === index)

        // 选择行不是全选
        if (!selectItem.isAllCheck) {
          this.itemSource.forEach(line => {
            line.isAllCheck = false
          })
        } else {
          // 如果全选行，单击后肯定就是false
          selectItem.isAllCheck = false
        }

        if (isCheck) {
          this.itemSource.forEach(line => {
            line[`isSelect${code}`] = line.rowIndex === index;
          })

          let allSelect = false

          for (let i = 0; i < selectCodes.length; i++) {
            const code = selectCodes[i]
            const noSelect = selectItem[code]
            if (!noSelect) {
              allSelect = true
              break
            }
          }
          selectItem.isAllCheck = !allSelect
        }


        this.setEditAloneItem(selectItem, code, isCheck)

      }


      this.$refs.list.setRowData(this.itemSource)
    },


    initColumns() {
      this.columns.forEach(item => {
        const waitOrder = ['rowIndex']
        if (!waitOrder.includes(item.field)) {
          item.cellRendererParams = {
            onChange: (data) => {

              this.onSetSelect(data)
            },
          };
        }
      })
      this.$refs.list.changeColumns(this.columns);
    },


  },
  created() {
  },
  mounted() {

  }
};
</script>
<style lang="scss">
.c-merge-client-dialog {
  .el-dialog {
    width: 1000px !important;
  }

  .el-dialog__body {
    padding-top: 0 !important;
  }

  .znl-input {
    margin-right: 10px;
  }

  .c-merge-client-dialog_title {
    font-size: 12px;
    text-decoration: underline;
    margin: 10px 0;
  }

  .c-merge-client-dialog_content {
    margin-bottom: 10px;
  }

  .c-merge-client-dialog_attribute {
    .znl-input {
      margin-bottom: 10px !important;
    }
  }
}
</style>
