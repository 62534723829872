import request from '@/utils/request'

export function getMenu() {
  return request({
    url: 'SeaManger/GetOpenSeaMenu',
    method: 'get'
  })
}

export function getList(data) {
  return request({
    url: 'SeaManger/GetListForOpenSea',
    method: 'post',
    data
  })
}

export function getAllClientList(data) {
  return request({
    url: 'SeaManger/GetAllClientList',
    method: 'post',
    data
  })
}
//保护
export function protectClient(data) {
  return request({
    url: 'SeaManger/ProtectClient',
    method: 'post',
    data
  })
}
//分配、转移、清洗
export function editButttonSeaClient(data) {
  return request({
    url: 'SeaManger/EditSeaClient',
    method: 'post',
    data
  })
}

export function getCommonList(){
  return request({
    url: 'SeaManger/JoinOpenSea',
    method: 'get',
  })
}

export function addCommon(data) {
  return request({
    url: 'SeaManger/JoinOpenSea',
    method: 'post',
    data
  })
}



