<template>
  <div class="c-change-client-type">
    <el-dialog
      v-el-drag-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="visible"
      @dragDialog="handleDrag">

      <section>
        <!-- <p class="m-margin--top-10">
          <span class="m-inline-block" style="width: 100px">客户名称: </span>
          <span class="c-change-client-type_content">{{ editItems.clientName }}</span>
        </p> -->
        <!-- <p class="m-margin--top-10">
          <span class="m-inline-block" style="width: 100px">当前客户类型: </span>
          <span class="c-change-client-type_content">{{ editItems.clientType | clientTypeFilter }}</span>
        </p> -->
        <p class="m-margin--top-10">
          <znl-input
            v-model="editItems.clientType"
            :select-options="clientOption"
            @change="UpdType"
            border
            form-type="select"
            title="更改客户类型: "
            title-width="100px"
            width="350px">
          </znl-input>
        </p>
        <p class="m-margin--top-10">
         <znl-input
            v-model="editItems.AduitReason"
            border
            class="znl-input"
            placeholder=""
            title="申请理由: "
            title-width="70px"
            type="textarea"
            width="350px">
          </znl-input>
        </p>

      </section>
      <section class="m-margin--top-10 m-dialog-footer">
        <el-button type="primary" @click="onSave">保存</el-button>
        <el-button @click="visible = false">取消</el-button>
      </section>
    </el-dialog>
  </div>
</template>
<script>
import elDragDialog from "@/directive/el-drag-dialog"; // base on element-ui
import {changeClientType} from "@/api/client";

const dataMap = {
  id: 'CompanyID',
  clientName: 'CompanyName',
  clientType: 'CustomerType',
  AduitReason:'AduitReason'
}
export default {
  name: 'ChangeTypeClient',
  directives: {
    elDragDialog
  },
  props: ['clientTypeList'],
  data() {
    return {
      visible: false,
      title: '更改客户类型',
      clientOption: [],
      editItems: {
        id: [],
        clientName: '',
        clientTypeName: '',
        clientType: '',
        AduitReason:''
      },
      ids:[],
      custType:""
    }
  },
  methods: {
    onOpen(data) {

      this.ids=data.map((item) => item.CompanyID)
      this.custType=data.map((item) => item.CustomerType)

      // data.forEach(item => {
      //   if(item.CustomerType=='StressProtect')
      //       {
      //          this.$message.warning('重点保护客户无法更改');
      //            throw new Error('End')
      //       }
      // });
       this.clientTypeList.clientType=''
       this.clientTypeList.clientTypeName=''

       this.editItems.AduitReason=''

      const newClientTypeList = this.clientTypeList.filter(item => item.name =="重点保护客户" || item.name =="一般客户" || item.name =="重点跟进客户")

      this.clientOption = newClientTypeList.map(item => Object.assign({}, {
        key: item.type,
        value: item.name
      }))

      this.visible = true

    },
    async UpdType(data)
    {

        const datainfo=data

        if(this.custType[0]==='StressFollow')
        {
          const ctype='重点跟进客户'
          if(datainfo.value==='一般客户')
        {
          this.editItems.AduitReason='将'+ctype+'改为'+'一般客户类型'
        }
        else if(datainfo.value==='重点跟进客户')
        {
          this.editItems.AduitReason='将'+ctype+'改为'+'重点跟进客户类型'
        }else if(datainfo.value==='重点保护客户')
        {
          this.editItems.AduitReason='将'+ctype+'改为'+'重点保护客户类型'
        }
        }
        else if(this.custType[0]==='Commonly')
        {
          const ctype='一般客户'
          if(datainfo.value==='一般客户')
        {
          this.editItems.AduitReason='将'+ctype+'改为'+'一般客户类型'
        }
        else if(datainfo.value==='重点跟进客户')
        {
          this.editItems.AduitReason='将'+ctype+'改为'+'重点跟进客户类型'
        }else if(datainfo.value==='重点保护客户')
        {
          this.editItems.AduitReason='将'+ctype+'改为'+'重点保护客户类型'
        }
        }
        else if(this.custType[0]==='AddInquiry')
        {
          const ctype='新增询价客户'
          if(datainfo.value==='一般客户')
        {
          this.editItems.AduitReason='将'+ctype+'改为'+'一般客户类型'
        }
        else if(datainfo.value==='重点跟进客户')
        {
          this.editItems.AduitReason='将'+ctype+'改为'+'重点跟进客户类型'
        }else if(datainfo.value==='重点保护客户')
        {
          this.editItems.AduitReason='将'+ctype+'改为'+'重点保护客户类型'
        }
        }
        else if(this.custType[0]==='StressProtect')
        {
          const ctype='重点保护客户'
          if(datainfo.value==='一般客户')
        {
          this.editItems.AduitReason='将'+ctype+'改为'+'一般客户类型'
        }
        else if(datainfo.value==='重点跟进客户')
        {
          this.editItems.AduitReason='将'+ctype+'改为'+'重点跟进客户类型'
        }else if(datainfo.value==='重点保护客户')
        {
          this.editItems.AduitReason='将'+ctype+'改为'+'重点保护客户类型'
        }
        }
        
    },
    async onSave() {
      
      const {editItems} = this

      const params = this.setDataMap(editItems, dataMap, 'save')
      params.CompanyID=this.ids
      this.editItems.AduitReason=''

      if(params.CustomerType==='StressProtect' && params.AduitReason==="")
      {
        this.$message.warning('更改重点保护客户必须填写申请原因');
                 throw new Error('End')
      }
      // else if(params.CustomerType!='StressProtect' )
      // {
      //   params.AduitReason='将'+this.custType+'改为'+params.CustomerType
      // }
       await changeClientType({
        Id: params.CompanyID,
        type: params.CustomerType,
        AduitReason: params.AduitReason
      })

      this.$message.success('客户类型修改提交成功')
      this.$emit('onSearch')
      this.visible = false
    },
    handleDrag() {

    }
  },
  created() {

  }

}
</script>
<style lang="scss">
.c-change-client-type {

  .el-dialog {
    width: 400px !important;
    min-width: 400px !important;
  }

  .znl-input-title {
    font-size: 14px;
    color: #606266;
    font-weight: normal;
  }

  .c-change-client-type_content {
    color: #000000;
    font-size: 12px;
  }
}
</style>
