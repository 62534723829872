<template>
  <div class="c-new-common-view">
    <el-dialog
      v-el-drag-dialog
      :close-on-click-modal="false"
      :title="`加入公海`"
      :visible.sync="visible">
      <section>
        <div>
          <znl-input
            v-model="editItem.Id"
            :border="true"
            :clearable="true"
            :height="32"
            :select-options="lists"
            form-type="select"
            title="公海名称"
            title-width="60px"
            ref="commonInput"
            width="240px">
          </znl-input>
        </div>
      </section>
      <section class="m-margin--top-10 m-margin--bottom-10">
        <grid
          ref="newCommonView"
          :columns="columns"
          :datas="itemSources"
          :selection="false"
          :suppressDragLeaveHidesColumns="true"
          :suppressPaginationPanel="false"
          domLayout="autoHeight"
          headerHeight="35"
          type="base">
        </grid>
      </section>
      <section class="m-dialog-footer c-seaCustomer-footer">
        <el-button type="primary" @click="onSave">确定</el-button>
        <el-button @click="visible = false">取消</el-button>
      </section>
    </el-dialog>
  </div>
</template>
<script>
import elDragDialog from "@/directive/el-drag-dialog"; // base on element-ui
import {getCommonList, addCommon} from "@/api/seaConfig";

export default {
  name: 'AddCommonView',
  directives: {
    elDragDialog,
  },
  data() {
    return {
      visible: false,
      columns: [
        {
          field: "indexRow",
          headerName: "序号",
          editable: false,
          cellClass: "t-c",
        },
        {
          field: "clientName",
          headerName: "客户名称",
          editable: false,
          cellClass: "t-c",
        },
        {
          field: "operation",
          headerName: "操作",
          editable: false,
          cellClass: "t-c",
          cellRendererFramework: "GridCellButtonRender",
        },
      ],
      lists: [],
      itemSources: [],
      editItem: {
        Id: 0
      }
    }
  },
  methods: {
    async onOpen(data) {
      this.itemSources = data
      this.editItem.Id = 0
      this.visible = true

      this.$nextTick(() => {
        this.$refs.commonInput.clear()
      })
    },
    async initCommonList() {
      const data = await getCommonList()
      this.lists = data.map(item => {
        return {
          key: item.SeaID,
          value: item.Name
        }
      })
    },
    initColumns() {
      const self = this;
      const handleColumn = this.columns.find((item) => item.field === "operation");
      if (handleColumn) {
        handleColumn.cellRendererParams = {
          buttons: self.setButtons,
        };
      }
      this.$nextTick(() => {
        this.$refs.newCommonView && this.$refs.newCommonView.changeColumns(this.columns);
      });
    },
    setButtons(params) {
      let data = params.data;
      let buttons = [];
      let self = this;
      const type = data.isSelect;
      const choiceBtn = {
        text: type ? "删除" : "加入",
        click() {
          self.onOpenSelect(data);
        },
      };
      buttons.push(choiceBtn);
      return buttons;
    },
    onOpenSelect(data) {
      data.isSelect = !data.isSelect;
      this.$refs.newCommonView.updateRowData(data);
    },


    async onSave() {
      const selectCompany = this.itemSources.filter(item => item.isSelect)
      const { Id } = this.editItem

      let err = null

      if(Id === 0) {
        err = '请选择需要加入的公海'
      }

      if (selectCompany.length === 0) {
        err = '请选择要加入公海的公司'
      }

      if(err) {
        this.$message.warning(err)
        return false
      }


      const param = {
        CompanyIds: selectCompany.map(item => item.id),
        OpenSeaIds: [Id]
      }

      await addCommon(param)

      this.$message.success('加入公海成功')

      this.$emit('onSearch')

      this.visible = false

    }
  },
  created() {
    this.initCommonList()
  },
  mounted() {
    this.initColumns()
  }

}
</script>
<style lang="scss">
.c-new-common-view {
  .el-dialog {
    min-width: 650px !important;
  }
}
</style>
